import {Button, Col, notification, Row, Spin, Tooltip} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, {useCallback, useRef, useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {LoadingOutlined} from '@ant-design/icons';
import update from 'immutability-helper';

import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import illustration_upload from '../images/illustration_upload.png';
const type = 'DragableUploadList';

const DragableUploadListItem = ({originNode, moveRow, file, fileList}) => {
    const ref = useRef(null);
    const index = fileList.indexOf(file);
    const [{isOver, dropClassName}, drop] = useDrop({
        accept: type,
        collect: monitor => {
            const {index: dragIndex} = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: item => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: {
            index,
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
    return (
        <div
            ref={ref}
            className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
            style={{
                cursor: 'move',
            }}>
            {file.status === 'error' ? errorNode : originNode}
        </div>
    );
};
const UploadFiles = () => {
    const navigate = useNavigate();
    const [listFile, setListFile] = useState([]);
    const [loading, setLoading] = useState(false);
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
                color: 'white',
            }}
            spin
        />
    );

    const handleSubmit = () => {
        const formData = new FormData();
        setLoading(true);
        listFile.map(e => {
            formData.append('files', e.originFileObj);
        });

        const options = {
            method: 'POST',
            auth: {
                username: process.env.REACT_APP_USERNAME,
                password: process.env.REACT_APP_PASSWORD,
            },
            data: formData,
            url: `https://dev.docbase.ai/api/v1/folders/${process.env.REACT_APP_FOLDER_ID}/images`,
        };
        axios(options)
            .then(res => {
                setLoading(false);
                navigate('/upload-success');
            })
            .catch(error => {
                setLoading(false);
                notification.error({
                    message: error.message,
                });
            });
    };

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = listFile[dragIndex];
            setListFile(
                update(listFile, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [listFile],
    );
    const onChange = e => {
        setListFile(e.fileList);
    };

    return (
        <Row>
            <Col xs={24}>
                <Container>
                    <Text>Tải lên hồ sơ</Text>
                    <BoxUpload>
                        <TextUpLoad>Hình ảnh/File PDF</TextUpLoad>
                    </BoxUpload>
                    <DndProvider backend={HTML5Backend}>
                        <Dragger
                            name="file"
                            accept=".png,.jpg,.jpeg"
                            multiple
                            beforeUpload={() => {
                                return false;
                            }}
                            fileList={listFile}
                            listType="picture"
                            onChange={onChange}
                            itemRender={(originNode, file, currFileList) => (
                                <DragableUploadListItem
                                    originNode={originNode}
                                    file={file}
                                    fileList={currFileList}
                                    moveRow={moveRow}
                                />
                            )}>
                            <ImageUpLoad src={illustration_upload} />
                            <ChooseFile>
                                <TextUploadContainer>Chọn file</TextUploadContainer>
                                <TitleUploadContainer>
                                    Kéo và thả file vào đây hoặc{' '}
                                    <button
                                        style={{
                                            border: 'none',
                                            padding: '0px',
                                            background: '#f4f6f8',
                                            color: '#1C0EC5',
                                            textDecoration: 'underLine',
                                            cursor: 'pointer',
                                        }}>
                                        Tải lên
                                    </button>{' '}
                                    từ thiết bị của bạn
                                </TitleUploadContainer>
                            </ChooseFile>
                        </Dragger>
                    </DndProvider>
                    <WrapBtn>
                        <Button
                            className="btnSubmit"
                            type="primary"
                            onClick={handleSubmit}
                            disabled={listFile?.length === 0 ? true : false}>
                            {loading ? <Spin indicator={antIcon} /> : 'Nộp hồ sơ'}
                        </Button>
                        <Button className="btnBack" style={{marginLeft: '8px'}} onClick={() => navigate(-1)}>
                            Quay lại
                        </Button>
                    </WrapBtn>
                </Container>
            </Col>
        </Row>
    );
};
export default UploadFiles;
const Container = styled.div`
    width: 1140px;
    height: auto;
    margin: 124px auto 0px auto;
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        width: auto;
        height: auto;
        margin: 124px 40px 0px 40px;
    }
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        width: auto;
        margin: 124px 30px 0px 30px;
    }
    @media screen and (max-width: 739px) {
        width: auto;
        margin: 100px 20px 0px 20px;
    }
`;
const Text = styled.h4`
    height: 39px;
    font-weight: 600;
    font-size: 28px;
    line-height: 140%;
    margin-bottom: 24px;
    color: rgba(0, 0, 0, 0.85);
`;
const BoxUpload = styled.div`
    width: 100%;
`;
const TextUpLoad = styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #637381;
`;

const ImageUpLoad = styled.img`
    width: 212px;
    height: 160px;
    margin: 40px 0px 40px 28px;
    @media screen and (max-width: 739px) {
        margin: 0px;
    }
`;
const TextUploadContainer = styled.h4`
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #212b36;
    margin: 0px;
    @media screen and (max-width: 739px) {
        text-align: center;
    }
`;
const TitleUploadContainer = styled.span`
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #637381;
    @media screen and (max-width: 739px) {
        font-size: 12px;
        text-align: center;
    }
`;
const ChooseFile = styled.div`
    padding: 0px;
    gap: 8px;
    width: 345px;
    height: 60px;
    margin-right: 28px;
    @media screen and (max-width: 739px) {
        margin: 0px;
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
    }
`;
const WrapBtn = styled.div`
    display: flex;
    margin-top: 26px;
    margin-bottom: 221px;
    @media screen and (max-width: 739px) {
        display: flex;
        justify-content: space-around;
    }
`;
