import {Col, Collapse, Row} from 'antd';
import React, {memo} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Summary from '../Component/Summary';
import imageBody from '../images/img (1).jpg';
import iconVaccin from '../images/vaccin.svg';
import iconSK from '../images/sk.svg';
import iconQl from '../images/Group 8143.svg';
import iconQlCN from '../images/quenloi.svg';
import iconThuongTat from '../images/thuongtat.svg';
import iconTuVong from '../images/tuvong.svg';
const {Panel} = Collapse;

const Home = () => {
    return (
        <div>
            <Row>
                <Col xs={24}>
                    <div>
                        <img src={imageBody} alt="" style={{width: '100%'}} />
                    </div>

                    <Background></Background>
                    <WrapText>
                        <div style={{position: 'absolute', bottom: '40px'}}>
                            <Text>BỒI THƯỜNG BẢO HIỂM</Text>
                            <Box></Box>
                        </div>
                    </WrapText>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Insurance>
                        <TextInsurance>Quyền lợi bảo hiểm</TextInsurance>
                        <TitleInsurance>
                            Chọn quyền lợi bồi thường đúng với trường hợp sử dụng của bạn để bắt đầu quy trình bồi thường.
                        </TitleInsurance>
                    </Insurance>
                </Col>
            </Row>
            <div className="indemnify">
                <Row gutter={[24, 40]}>
                    <Col xs={24} sm={12} md={8}>
                        <Link to="/quyen-loi-tro-cap-nam-vien-chi-tra-theo-ngay-nam-vien">
                            <Summary title="Quyền lợi trợ cấp nằm viện - chi trả theo ngày nằm viện" src={iconVaccin} />
                        </Link>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Link to="quyen-loi-cham-soc-suc-khoe-chi-tra-theo-chi-phi-thuc-te">
                            <Summary title="Quyền lợi chăm sóc sức khỏe - Chi trả theo chi phí thực tế" src={iconSK} />
                        </Link>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Link to="quyen-loi-benh-hiem-ngheo-benh-ung-thu">
                            <Summary title="Quyền lợi bệnh hiểm nghèo/Bệnh ung thư" src={iconQl} />
                        </Link>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Link to="quyen-loi-tai-nan-ca-nhan">
                            <Summary title="Quyền lợi tai nạn cá nhân" src={iconQlCN} />
                        </Link>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Link to="quyen-loi-thuong-tat-toan-bo-vinh-vien">
                            <Summary title="Quyền lợi thương tật toàn bộ & vĩnh viễn" src={iconThuongTat} />
                        </Link>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Link to="quyen-loi-tu-vong">
                            <Summary title="Quyền lợi tử vong" src={iconTuVong} />
                        </Link>
                    </Col>
                </Row>
            </div>
            <Row style={{background: '#F5F6F9'}}>
                <Col className="question" xs={24}>
                    <BoxQuestion>
                        <TextQuestion>Câu hỏi thường gặp</TextQuestion>
                    </BoxQuestion>
                    <WrapCollapse>
                        <Collapse
                            defaultActiveKey={['1']}
                            expandIconPosition="end"
                            bordered={false}
                            // ghost="true"
                        >
                            <Panel header="Khi nào tôi có thể nộp yêu cầu giải quyết quyền lợi bảo hiểm?" key="1">
                                <p>
                                    Quý khách vui lòng gửi yêu cầu giải quyết quyền lợi bảo hiểm trong vòng 12 tháng kể từ
                                    ngày xảy ra sự kiện bảo hiểm.
                                </p>
                            </Panel>
                            <Panel header="Trong bao lâu thì yêu cầu bồi thường của tôi được giải quyết?" key="2">
                                <p>
                                    Đối với quyền lợi bảo hiểm trợ cấp nằm viện và quyền lợi chăm sóc sức khỏe, chúng tôi
                                    sẽ giải quyết nhanh chóng các yêu cầu của Quý khách. Các hồ sơ đầy đủ và hợp lệ, chúng
                                    tôi sẽ thông báo quyết định đến Quý khách trong vòng 1 ngày làm việc qua email và tin
                                    nhắn. Trường hợp cần Quý khách bổ sung chứng từ, chúng tôi sẽ thông báo đến Quý khách
                                    trong vòng 1 ngày làm việc qua email và tin nhắn.
                                </p>
                                <br />
                                <p>
                                    Đối với yêu cầu giải quyết quyền lợi bảo hiểm tai nạn, bệnh hiểm nghèo/bệnh ung thư,
                                    thương tật toàn bộ vĩnh viễn, hoặc tử vong: các hồ sơ đầy đủ và hợp lệ, chúng tôi sẽ
                                    thông báo quyết định đến Quý khách trong vòng 10 ngày làm việc qua email và tin nhắn.
                                    Trường hợp cần bổ sung chứng từ, chúng tôi sẽ thông báo đến Quý khách trong vòng 2 ngày
                                    làm việc qua email và tin nhắn.
                                </p>
                            </Panel>
                            <Panel header="Khi nào Bảo Việt sẽ chi trả Quyền lợi bảo hiểm cho tôi?" key="3">
                                <p>
                                    Đối với quyền lợi bảo hiểm trợ cấp nằm viện và quyền lợi chăm sóc sức khỏe, chúng tôi
                                    sẽ chuyển số tiền được chi trả cho Quý khách trong 01 ngày làm việc kể từ ngày phát
                                    hành Thư báo chấp thuận.
                                </p>
                                <br />
                                <p>
                                    Đối với yêu cầu giải quyết quyền lợi bảo hiểm tai nạn, bệnh hiểm nghèo/bệnh ung thư,
                                    thương tật toàn bộ vĩnh viễn, hoặc tử vong, chúng tôi sẽ chuyển số tiền được chi trả
                                    cho Quý khách trong 03 ngày làm việc kể từ ngày phát hành Thư báo chấp thuận.
                                </p>
                            </Panel>
                            <Panel
                                header="Bảo Việt sẽ chi trả Quyền lợi bảo hiểm cho tôi theo phương thức nào (tiền mặt, chuyển khoản..). Làm thế nào để tôi kiểm tra trạng thái yêu cầu giải quyết quyền lợi bảo hiểm của tôi?"
                                key="4">
                                <p>
                                    Khi yêu cầu giải quyết Quyền lợi bảo hiểm của Quý khách được chấp thuận, chúng tôi sẽ
                                    chuyển số tiền được chi trả theo phương thức thanh toán Quý khách đã cung cấp.
                                </p>
                            </Panel>
                            <Panel header="Dịch vụ hợp đồng" key="5">
                                <p>
                                    Quý khách có 21 ngày kể từ ngày nhận được Hợp đồng lần đầu tiên để cân nhắc Hợp đồng
                                    này có phù hợp với nhu cầu của mình hay không. Hợp đồng vẫn có hiệu lực trong suốt Thời
                                    gian cân nhắc.
                                </p>
                                <br />
                                <p>
                                    Trong thời gian này, Quý khách cần kiểm tra lại toàn bộ Hợp đồng, bao gồm các thông tin
                                    của Quý khách đã kê khai trong Chứng nhận bảo hiểm, Hồ sơ yêu cầu bảo hiểm để đảm bảo
                                    các thông tin được ghi nhận đầy đủ và chính xác. Nếu Quý khách thấy bất kỳ thông tin
                                    nào chưa được ghi nhận đầy đủ hay chính xác, Quý khách cần thông báo ngay để được xác
                                    nhận và điều chỉnh kịp thời.
                                </p>
                                <br />
                                <p>
                                    Trong thời gian cân nhắc của Hợp đồng, nếu Quý khách quyết định không tiếp tục duy trì
                                    hợp đồng, Quý khách có thể gửi yêu cầu hủy Hợp đồng để nhận lại khoản phí bảo hiểm ban
                                    đầu đã đóng, không có lãi, sau khi đã khấu trừ các chi phí kiểm tra sức khỏe, nếu có.
                                </p>
                                <br />
                                <p>
                                    Hợp đồng sẽ chấm dứt hiệu lực vào ngày xác nhận trên thư xác nhận chấm dứt Hợp đồng
                                    trong thời gian cân nhắc.
                                </p>
                            </Panel>
                            <Panel header="Đóng phí bảo hiểm" key="6">
                                <p>
                                    Chúng tôi cung cấp nhiều kênh và phương thức đóng phí thuận lợi và đa dạng để Quý khách
                                    có thể chủ động lựa chọn kênh đóng phí thuận tiện nhất cho mình
                                </p>
                                <br />
                                <p>
                                    Trong vòng 3 ngày làm việc, chúng tôi sẽ gửi đến Quý khách xác nhận đóng phí qua tin
                                    nhắn/email. Quá thời gian này, nếu Quý khách chưa nhận được tin nhắn/email xác nhận của
                                    chúng tôi, vui lòng kiểm tra Dịch vụ Trực tuyến hoặc liên hệ với chúng tôi.
                                </p>
                            </Panel>
                            <Panel header="Tạm ứng tiền mặt" key="7">
                                <p>
                                    Là khoản tiền Quý khách có thể yêu cầu chúng tôi tạm ứng từ giá trị hoàn lại của hợp
                                    đồng bảo hiểm để sử dụng cho các nhu cầu tài chính ngắn hạn.
                                </p>
                                <br />
                                <p>
                                    Quý khách có thể yêu cầu Tạm ứng tiền mặt khi hợp đồng bảo hiểm có Giá trị hoàn lại và
                                    phát sinh từ sau năm thứ 2 trở đi. Cần lưu ý là một số sản phẩm bảo hiểm như Tử kỳ hoặc
                                    Tai nạn không có Giá trị hoàn lại do đó không thể áp dụng quyền lợi Tạm ứng tiền mặt
                                </p>
                                <br />
                                <p>
                                    Quý khách có thể yêu cầu tạm ứng số tiền tối đa không quá 80% Giá trị hoàn lại và tối
                                    thiểu là 5.000.000 đồng mỗi lần tạm ứng đối với sản phẩm liên kết chung
                                </p>
                            </Panel>
                            <Panel header="Quyền lợi hợp đồng" key="8">
                                <p>
                                    Quyền lợi tiền mặt là khoản tiền được chi trả theo định kỳ quy định tại Quy tắc và Điều
                                    khoản sản phẩm trong thời gian Hợp đồng bảo hiểm có hiệu lực.
                                </p>
                                <br />
                                <p>
                                    Vui lòng xem tại trang lãi suất. Tỷ lệ lãi suất công bố hàng năm có thể thay đổi tùy
                                    theo diễn biến đầu tư và lãi suất thị trường.
                                </p>
                                <br />
                                <p>
                                    Việc khấu trừ tự động này nhằm mang lại quyền lợi cao nhất cho Quý khách đồng thời thực
                                    hiện đúng theo quy định tại Quy tắc và Điều khoản sản phẩm.
                                </p>
                                <br />
                                <p>
                                    Là số tiền chúng tôi sẽ chi trả cho Bên mua Bảo hiểm nếu hợp đồng bảo hiểm vẫn có hiệu
                                    lực và Người được Bảo hiểm vẫn còn sống vào ngày đáo hạn Hợp đồng.
                                </p>
                            </Panel>
                        </Collapse>
                    </WrapCollapse>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <BoxCopyRight>
                        <span>© 2023 Bản quyền thuộc về Công ty cổ phần Công nghệ Computer Vision Việt Nam</span>
                    </BoxCopyRight>
                </Col>
            </Row>
        </div>
    );
};

export default memo(Home);
const Text = styled.span`
    width: 592px;
    height: 70px;
    font-style: normal;
    font-weight: 700;
    font-size: 49.887px;
    line-height: 140%;
    color: #ffffff;
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        font-size: 30px;
    }
    @media screen and (max-width: 739px) {
        font-size: 18px;
    }
`;
const Box = styled.div`
    width: 98px;
    height: 9px;
    background: #ec1c2a;
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        width: 58px;
        height: 5px;
    }
    @media screen and (max-width: 739px) {
        width: 42px;
        height: 3px;
    }
`;
const WrapText = styled.div`
    width: 1140px;
    margin: 0px auto;
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        margin: 0px 40px;
    }
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        margin: 0px 30px;
    }
    @media screen and (max-width: 739px) {
        margin: 0px 20px;
    }
`;
const Background = styled.div`
    width: 100%;
    height: 260px;
    position: absolute;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
`;
const Insurance = styled.div`
    width: 1140px;
    height: 88px;
    margin: 80px auto 0px auto;
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        width: auto;
        height: auto;
        margin: 80px 30px 0px 30px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        width: auto;
        margin: 80px 40px 0px 40px;
    }
    @media screen and (max-width: 739px) {
        width: auto;
        margin: 40px 20px 40px 20px;
    }
`;
const TextInsurance = styled.h4`
    height: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    color: #000000;
    @media screen and (max-width: 739px) {
        font-size: 22px;
    }
`;
const TitleInsurance = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    width: 749px;
    height: 22px;
    color: rgba(0, 0, 0, 0.65);
    @media screen and (max-width: 739px) {
        width: auto;
        line-height: 0px;
        font-size: 12px;
    }
`;
const TextQuestion = styled.h4`
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    width: 355px;
    height: 50px;
    @media screen and (max-width: 739px) {
        font-size: 26px;
        width: fit-content;
    }
    @media screen and (max-width: 1440px) {
        margin: 80px auto 40px auto;
    }
`;
const BoxQuestion = styled.div`
    width: 1140px;
    height: 50px;
    margin: 80px auto 40px auto;
    @media screen and (max-width: 1440px) {
        width: auto;
        margin: 80px auto 40px auto;
    }
`;
const WrapCollapse = styled.div`
    width: 1140px;
    padding: 0px;
    gap: 80px;
    margin: 40px auto 124px auto;
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        margin: 40px 10px 124px 10px;
        width: auto;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        margin: 40px 40px 0px 40px;
        width: auto;
    }
    @media screen and (max-width: 739px) {
        margin: 40px 20px 0px 20px;
        width: auto;
    }
`;
const BoxCopyRight = styled.div`
    width: 100%;
    height: 68px;
    background: #ffffff;
    box-shadow: 0px -1px 0px #e9ebf0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 739px) {
        margin-top: 40px;
        text-align: center;
    }
`;
