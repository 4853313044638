import {Col, Row} from 'antd';
import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import imgSuccess from '../images/image 1.png';

const UploadSuccess = () => {
    return (
        <Row>
            <Col xs={24}>
                <Container>
                    <Image src={imgSuccess} alt="" />

                    <WrapText>
                        <Title>Gửi yêu cầu thành công</Title>
                        <TextUpload>
                            Yêu cầu giải quyết quyền lợi bảo hiểm của quý khách đã được tiếp nhận. Chúng tôi sẽ phản hồi
                            trong vòng 24 giờ theo thời gian làm việc
                        </TextUpload>
                        <Link to="/">
                            <Btn>Về trang chủ</Btn>
                        </Link>
                    </WrapText>
                </Container>
            </Col>
        </Row>
    );
};

export default UploadSuccess;
const Container = styled.div`
    width: 490px;
    height: 491px;
    margin: 156px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 739px) {
        width: auto;
    }
`;
const Image = styled.img`
    width: 300px;
    height: 300px;
    @media screen and (max-width: 739px) {
        width: 250px;
        height: 250px;
    }
`;
const WrapText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 739px) {
        text-align: center;
    }
`;
const Title = styled.h4`
    width: 325px;
    height: 39px;
    font-weight: 500;
    font-size: 28px;
    line-height: 140%;
    color: #000000;
    margin-top: 24px;
    @media screen and (max-width: 739px) {
        font-size: 24px;
    }
`;
const TextUpload = styled.span`
    width: 490px;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #000000;
    @media screen and (max-width: 739px) {
        font-size: 12px;
        width: auto;
        margin: 0px 20px;
    }
`;
const Btn = styled.button`
    border: none;
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #ffffff;
    padding: 11px 22px;
    gap: 8px;
    width: 144px;
    height: 48px;
    background: #ec1c2a;
    border-radius: 8px;
    cursor: pointer;
`;
