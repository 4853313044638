import {Col, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {namVien, sucKhoe, hiemNgheo, baoHiem, taiNan, tuVong} from '../data';
import Ellipse from '../images/Ellipse.svg';

const Indemnify = () => {
    const {slug} = useParams();
    const [data, setData] = useState([]);
    const [newArr, setNewArr] = useState([]);
    const [title, setTitle] = useState('');
    useEffect(() => {
        switch (slug) {
            case 'quyen-loi-tro-cap-nam-vien-chi-tra-theo-ngay-nam-vien':
                setData(namVien);
                break;
            case 'quyen-loi-cham-soc-suc-khoe-chi-tra-theo-chi-phi-thuc-te':
                setData(sucKhoe);
                break;
            case 'quyen-loi-benh-hiem-ngheo-benh-ung-thu':
                setData(hiemNgheo);
                break;
            case 'quyen-loi-tai-nan-ca-nhan':
                setData(taiNan);
                break;
            case 'quyen-loi-thuong-tat-toan-bo-vinh-vien':
                setData(baoHiem);
                break;
            case 'quyen-loi-tu-vong':
                setData(tuVong);
                break;
            default:
        }
    }, [slug]);

    useEffect(() => {
        data?.map(e => {
            if (e.Title !== undefined) {
                setTitle(e.Title);
            }
            setNewArr(e);
        });
    }, [data]);

    return (
        <>
            <Row>
                <Col xs={24}>
                    <HeaderStyled>
                        <BoxInterest>
                            <TextInterest>{title}</TextInterest>
                            <Background></Background>
                        </BoxInterest>
                    </HeaderStyled>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <BoxDocument>
                        <WrapDocument>
                            <TextDocument>Chuẩn bị chứng từ</TextDocument>
                            <TitleDocument>
                                Quý khách chuẩn bị đầy đủ các chứng từ theo yêu cầu dưới đây để được giải quyết yêu cầu bồi
                                thường
                            </TitleDocument>
                        </WrapDocument>
                        <BoxDeathCertificate>
                            {newArr?.content?.map((e, i) => {
                                return (
                                    <Content key={i}>
                                        <BoxEllipse src={Ellipse}></BoxEllipse>
                                        <TitleContent>{e}</TitleContent>
                                    </Content>
                                );
                            })}
                            <Link to="/condition">
                                <ButtonStyled>Tôi đã chuẩn bị đầy đủ. Bắt đầu!</ButtonStyled>
                            </Link>
                        </BoxDeathCertificate>
                    </BoxDocument>
                </Col>
            </Row>
        </>
    );
};

export default Indemnify;

const BoxInterest = styled.div`
    width: 1140px;
    margin: auto;
    padding-top: 170px;
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        height: 79px;
        width: 1140px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        margin: 0px 40px 0px 40px;
        width: auto;
        height: auto;
    }
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        position: absolute;
        left: 40px;
        width: auto;
        height: auto;
    }

    @media screen and (max-width: 739px) {
        width: auto;
        height: auto;
        position: absolute;
        left: 20px;
        bottom: 20px;
        padding: 0px;
    }
`;
const TextInterest = styled.h4`
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    color: #ec1c2a;
    @media screen and (max-width: 739px) {
        font-size: 16px;
    }
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        font-size: 24px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        font-size: 32px;
    }
`;
const Background = styled.div`
    width: 98px;
    height: 9px;
    background: #ec1c2a;
    @media screen and (max-width: 739px) {
        width: 50px;
        height: 5px;
    }
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        width: 70px;
        height: 7px;
    }
`;
const HeaderStyled = styled.div`
    width: 100%;
    height: 289px;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 739px) {
        height: 159px;
    }
`;
const WrapDocument = styled.div`
    padding: 0px;
    gap: 16px;
    width: 796px;
    height: 77px;
    @media screen and (max-width: 739px) {
        width: auto;
        height: auto;
    }
    @media screen and (min-width: 739px) and (max-width: 1023px) {
        width: auto;
    }
`;
const BoxDocument = styled.div`
    width: 1140px;
    margin: 40px auto 48px auto;
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        width: 820px;
        margin: 40px 470px 48px 150px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        width: auto;
        margin: 40px 40px 48px 40px;
    }
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        margin: 40px 40px 48px 40px;
        width: auto;
    }
    @media screen and (max-width: 739px) {
        width: auto;
        margin: 20px;
    }
`;
const TextDocument = styled.h4`
    font-weight: 600;
    font-size: 28px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        font-size: 24px;
    }
`;
const TitleDocument = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.65);
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        font-size: 14px;
    }
    @media screen and (max-width: 739px) {
        font-size: 12px;
    }
`;
const BoxEllipse = styled.img``;
const BoxDeathCertificate = styled.div`
    margin-top: 40px;
`;
const Content = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
`;
const TitleContent = styled.span`
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    white-space: pre-line;

    @media screen and (min-width: 740px) and (max-width: 1023px) {
        font-size: 14px;
    }
    @media screen and (max-width: 739px) {
        font-size: 14px;
    }
`;
const ButtonStyled = styled.button`
    border: none;
    padding: 17px 22px;
    margin-top: 33px;
    width: 298px;
    height: 56px;
    background: #ec1c2a;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #ffffff;
    cursor: pointer;
    @media screen and (max-width: 739px) {
        width: 100%;
    }
`;
