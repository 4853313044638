import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.less';
import Header from './Component/Header';
import Condition from './Page/Condition';
import Home from './Page/Home';
import Indemnify from './Page/Indemnify';
import PersonalInFor from './Page/PersonalInFor';
import UploadFiles from './Page/UploadFiles';
import UploadSuccess from './Page/UploadSuccess';

function App() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path=":slug" element={<Indemnify />} />
                <Route path="/condition" element={<Condition />} />
                <Route path="/upload-files" element={<UploadFiles />} />
                <Route path="/upload-success" element={<UploadSuccess />} />
                <Route path="/personal-information" element={<PersonalInFor />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
