import {Button, Col, DatePicker, Form, InputNumber, Row, Select} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

const PersonalInFor = () => {
    const navigate = useNavigate();
    const onFinish = values => {
        navigate('/upload-files');
    };

    const onFinishFailed = errorInfo => {};
    const onChange = onChange => {};
    const handleChange = value => {};
    return (
        <Row>
            <Col xs={24}>
                <BoxInFor>
                    <TitleInFor>Thông tin cá nhân</TitleInFor>
                    <FormInFor>
                        <Form
                            layout="vertical"
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off">
                            <Form.Item label="Số CMND/CCCD/Hộ chiếu" name="CCCD">
                                <InputNumber placeholder="Số CMND/CCCD" controls={false} />
                            </Form.Item>

                            <Form.Item label="Ngày sinh" name="dob">
                                <DatePicker onChange={onChange} placeholder="Ngày sinh" />
                            </Form.Item>

                            <Form.Item label="Người được bảo hiểm" name="insurance">
                                <Select
                                    placeholder="Chọn"
                                    onChange={handleChange}
                                    options={[
                                        {
                                            value: 'Nguyễn Văn A',
                                            label: 'Nguyễn Văn A',
                                        },
                                        {
                                            value: 'Nguyễn Văn B',
                                            label: 'Nguyễn Văn B',
                                        },
                                        {
                                            value: 'Nguyễn Văn C',
                                            label: 'Nguyễn Văn C',
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item label="Số điện thoại" name="sdt">
                                <InputNumber placeholder="Nhập" controls={false} />
                            </Form.Item>

                            <WrapBtn>
                                <Form.Item wrapperCol={{}}>
                                    <Button htmlType="submit" type="primary" className="btnSubmit">
                                        Tiếp Tục
                                    </Button>
                                </Form.Item>
                                <Form.Item wrapperCol={{}}>
                                    <Button className="btnBack" onClick={() => navigate(-1)}>
                                        Quay lại
                                    </Button>
                                </Form.Item>
                            </WrapBtn>
                        </Form>
                    </FormInFor>
                </BoxInFor>
            </Col>
        </Row>
    );
};

export default PersonalInFor;
const BoxInFor = styled.div`
    width: 1140px;
    margin: 124px auto 0px auto;
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        width: auto;
        margin: 124px 40px 0px 40px;
    }
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        width: auto;
        margin: 124px 30px 0px 30px;
    }
    @media screen and (max-width: 739px) {
        width: auto;
        margin: 100px 20px 0px 20px;
    }
`;
const FormInFor = styled.div`
    padding: 16px;
    gap: 32px;
    width: 556px;
    height: 380px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    margin-top: 24px;
    @media screen and (max-width: 739px) {
        width: auto;
    }
`;
const TitleInFor = styled.h4`
    font-weight: 600;
    font-size: 28px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
    margin: 0px;
`;
const WrapBtn = styled.div`
    padding: 0px 16px;
    gap: 8px;
    width: 259px;
    height: 48px;
    display: flex;
    margin-top: 44px;
`;
