import {Button, Checkbox, Col, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

const Condition = () => {
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();
    const onChange = e => {
        setIsChecked(e.target.checked);
    };
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <Row>
            <Col xs={24}>
                <BoxCondition>
                    <TitleCondition>Điều khoản và Điều kiện</TitleCondition>
                    <TextCondition>
                        Khi nộp hồ sơ yêu cầu giải quyết quyền lợi bảo hiểm ("QLBH") thông qua trang điện tử này, Tôi Hiểu
                        và Đồng ý rằng:
                    </TextCondition>
                    <Content>
                        <ol>
                            <ListCondition>
                                Tất cả các thông tin do Tôi cung cấp khi yêu cầu giải quyết QLBH này là đầy đủ và đúng theo
                                sự hiểu biết của Tôi.
                            </ListCondition>
                            <ListCondition>
                                Tôi xác nhận Tôi là Bên Mua Bảo Hiểm của Hợp đồng cá nhân hoặc Người Được Bảo Hiểm của Hợp
                                đồng Tổ chức tham gia cho nhân viên. Thông tin cá nhân mà Tôi cung cấp tại đây là thông tin
                                của chính tôi. Tôi hiểu "Yêu cầu giải quyết QLBH trực tuyến" là một phần của Quy trình giải
                                quyết QLBH theo quy định của Công ty CPCN Computer Vision Việt Nam (CVS) và Tôi sẽ không
                                được hưởng bất kỳ khoản thanh toán nào cho đến khi toàn bộ quy trình hoàn tất.
                            </ListCondition>
                            <ListCondition>
                                Khi Tôi thực hiện yêu cầu giải quyết QLBH này, Tôi đảm bảo Tôi đã được ủy quyền bởi Người
                                được Bảo hiểm của Hợp đồng về việc (a) cung cấp thông tin của Người được Bảo hiểm cho CVS;
                                và (b) nhận các thông tin do CVS cung cấp. Tôi cũng hiểu những thông tin được yêu cầu từ
                                CVS là cần thiết cho việc xử lý yêu cầu giải quyết QLBH.
                            </ListCondition>
                            <ListCondition>
                                Tất cả thông tin sẽ được CVS bảo mật theo "Chính Sách Bảo Mật Thông Tin" (Chính Sách).
                                Trong trường hợp Tôi hoặc Người được Bảo hiểm chưa tham khảo Chính Sách từ trước, Tôi hiểu
                                rằng Tôi và Người được Bảo Hiểm cần tìm hiểu thông tin này từ đại lý của CVS hoặc thông qua
                                trang thông tin điện tử của CVS tại https://computervision.vn
                            </ListCondition>
                            <ListCondition>
                                Tôi đồng ý và ủy quyền cho Manulife trước khi chi trả bất kỳ QLBH nào, Manulife có quyền
                                cấn trừ các khoản thanh toán do Tôi cung cấp thông tin sai hay thiếu trong quá trình yêu
                                cầu giải quyết QLBH.
                            </ListCondition>
                            <ListCondition>
                                Tôi hiểu rằng với mục đích kiểm toán, Tôi có thể được CVS yêu cầu nộp bổ sung chứng
                                từ/Chứng từ gốc của hồ sơ yêu cầu giải quyết QLBH. Nếu Tôi nhận được yêu cầu đó, Tôi cam
                                kết sẽ gửi sớm chứng từ/Chứng từ gốc cho CVS. Trong trường hợp Tôi không thể hoàn tất yêu
                                cầu đó hoặc các chứng từ/Chứng từ gốc được gửi là sai sự thật, giả mạo, CVS có quyền từ
                                chối yêu cầu giải quyết QLBH.
                            </ListCondition>
                            <ListCondition>
                                Tôi KHÔNG phải là công dân Mỹ hoặc là người có nghĩa vụ kê khai thuế tại Mỹ hoặc có một
                                trong các chỉ dấu sau: (i) số hộ chiếu hoặc các giấy tờ cư trú tại Mỹ, (ii) mã số thuế tại
                                Mỹ, hoặc (iii) nơi sinh, số điện thoại, địa chỉ liên lạc tại Mỹ vào thời điểm yêu cầu thay
                                đổi hợp đồng bảo hiểm.
                            </ListCondition>
                            <ListCondition>
                                Trường hợp có bất kỳ thay đổi nào liên quan đến các thông tin đề cập nêu trên, Tôi sẽ thông
                                báo cho CVS trong vòng 30 ngày kể từ ngày có phát sinh thay đổi.
                            </ListCondition>
                            <ListCondition>
                                Tôi đồng ý rằng CVS sẽ được quyền thực hiện các công việc cần thiết để tuân thủ các quy
                                định của pháp luật hiện hành, bao gồm cả việc thu thập và cung cấp thông tin của Tôi cho
                                các cơ quan có thẩm quyền trong và ngoài nước để đáp ứng các yêu cầu của pháp luật.
                            </ListCondition>
                            <ListCondition>
                                Tôi đồng ý cung cấp số điện thoại di động của Tôi để CVS cung cấp các thông tin liên quan
                                đến yêu cầu giải quyết QLBH của Tôi.
                            </ListCondition>
                            <ListCondition>
                                Bản Điều khoản sử dụng này được lập bằng cả Tiếng Việt và Tiếng Anh. Trong trường hợp có
                                mâu thuẫn giữa hai ngôn ngữ thì bản Tiếng Việt sẽ được áp dụng.
                            </ListCondition>
                        </ol>
                    </Content>
                    <BoxCheck>
                        <Checkbox onChange={onChange}>Tôi đồng ý với Điều khoản và Điều kiện</Checkbox>
                    </BoxCheck>

                    <WrapBtn>
                        <Link to={isChecked ? '/personal-information' : ''}>
                            <Button className="btnSubmit" type="primary">
                                Tiếp tục
                            </Button>
                        </Link>
                        <Button className="btnBack" style={{marginLeft: '8px'}} onClick={() => navigate(-1)}>
                            Quay lại
                        </Button>
                    </WrapBtn>
                </BoxCondition>
            </Col>
        </Row>
    );
};

export default Condition;
const BoxCondition = styled.div`
    margin: 124px auto 0px auto;
    width: 1140px;
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        width: 828px;
        position: absolute;
        left: 150px;
        top: 124px;
        margin: 0px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        width: auto;
        padding: 50px 40px 0px 40px;
    }
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        width: auto;
        margin: 100px 30px 0px 30px;
    }
    @media screen and (max-width: 739px) {
        position: absolute;
        left: 20px;
        right: 20px;
        top: 0px;
        width: auto;
    }
`;
const TitleCondition = styled.h4`
    font-weight: 600;
    font-size: 28px;
    line-height: 140%;
    margin-bottom: 24px;
    color: rgba(0, 0, 0, 0.85);
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        font-size: 22px;
    }
    @media screen and (max-width: 739px) {
        font-size: 18px;
    }
`;
const TextCondition = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    width: 800px;
    height: 44px;
    color: #000000;
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        font-size: 15px;
    }
    @media screen and (max-width: 739px) {
        font-size: 13px;
    }
`;
const Content = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    width: 100%;
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        font-size: 14px;
    }
`;
const ListCondition = styled.li`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: justify;
    margin-top: 24px;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.85);
    @media screen and (max-width: 739px) {
        font-size: 12px;
    }
`;
const BoxCheck = styled.div`
    padding: 0px 20px;
    margin-top: 24px;
`;
const WrapBtn = styled.div`
    margin: 44px 0px 57px 20px;
    display: flex;
    @media screen and (max-width: 739px) {
        display: flex;
        justify-content: space-around;
    }
`;
