import {Col, Row} from 'antd';
import React, {memo} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import logo from '../images/Web_logo.svg';

const Header = () => {
    return (
        <Row>
            <Col xs={24}>
                <div style={{position: 'absolute', zIndex: '999', width: '100%'}}>
                    <WrapImage>
                        <Link to="/">
                            <Logo src={logo} alt="" />
                        </Link>
                        {/* <HotlineImage src="hotline.png" alt="" /> */}
                    </WrapImage>
                </div>
            </Col>
        </Row>
    );
};

export default memo(Header);
const Logo = styled.img`
    width: 296px;
    height: 52px;
    margin-top: 24px;
    cursor: pointer;
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        margin: 24px 0px 0px 40px;
    }
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        width: 246px;
        height: 42px;
        margin: 24px 0px 0px 30px;
    }
    @media screen and (max-width: 739px) {
        width: 180px;
        height: 32px;
        margin: 14px 0px 0px 20px;
    }
`;
// const HotlineImage = styled.img`
//     width: 161px;
//     height: 24px;
//     margin-top: 38px;
//     cursor: pointer;
//     @media screen and (min-width: 1024px) and (max-width: 1199px) {
//         margin: 24px 40px 0px 0px;
//     }
//     @media screen and (min-width: 740px) and (max-width: 1023px) {
//         width: 141px;
//         height: 20px;
//         margin: 38px 30px 0px 0px;
//     }
//     @media screen and (max-width: 739px) {
//         width: 100px;
//         height: 15px;
//         margin: 18px 20px 0px 0px;
//     }
// `;
const WrapImage = styled.div`
    width: 1140px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        width: auto;
    }
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        width: auto;
    }
    @media screen and (max-width: 739px) {
        width: auto;
    }
`;