export const namVien = [
  {
    Title: "Quyền lợi Trợ cấp nằm viện - chi trả theo ngày nằm viện",
  },
  {
    content: [
      "Giấy ra viện (bắt buộc)",
      " Bảng kê chi phí điều trị nội trú, hoá đơn viện phí (bắt buộc)",
      " Kết quả xét nghiệm, chẩn đoán hình ảnh (nếu có)",
      " Giấy chứng nhận phẫu thuật (trong trường hợp có phẫu thuật)",
      " Tóm tắt bệnh án / Bản sao Hồ sơ bệnh án (trong trường hợp nằm viện trên 10 ngày, nằm viện có phẫu thuật, nằm viện tại khoa chăm sóc đặc biệt ICU)",
    ],
  },
];
export const sucKhoe = [
  {
    Title: "Quyền lợi chăm sóc sức khoẻ - chi trả theo chi phí thực tế:",
  },
  {
    content: [
      "1: Ngoại trú",
      "Sổ khám bệnh, toa thuốc, giấy chứng nhận bệnh lý",
      "Các chỉ định của bác sĩ và kết quả xét nghiệm, chuẩn đoán hình ảnh",
      "Hóa đơn, biên lai, phiếu thu viện phí (đối với chi phí lớn hơn 200.000VND cần phải có hóa đơn tài chính / hóa đơn GTGT do cơ sở y tế phát hành)",
      "Bảng kê chi tiết chi phí khám, điều trị ngoại trú",
      "2: Nội trú",
      "Giấy ra viện, giấy chuyển viện",
      " Giấy chứng nhận phẫu thuật",
      " Toa thuốc",
      "Chỉ định của bác sĩ và kết quả xét nghiệm, chuẩn đoán hình ảnh",
      "Tóm tắt bệnh án/ bản sao hồ sơ bệnh án (đối với nằm viện điều trị ung thư, cấy ghép nội tạng, phẫu thuật, nằm viện trên 15 ngày)",
      "Hóa đơn, biên lai, phiếu thu viện phí (đối với chi phí lớn hơn 200.000VND cần phải có hóa đơn tài chính / hóa đơn GTGT do cơ sở y tế phát hành)",
      "Bảng kê chi tiết chi phí điều trị nội trú",
    ],
  },
];
export const hiemNgheo = [
  {
    Title: "Quyền lợi bệnh hiểm nghèo/ bệnh ung thư:",
  },
  {
    content: [
      "Giấy ra viện/ giấy chứng nhận phẫu thuật",
      "Kết quả giải phẫu bệnh lý",
      "Các xét nghiệm, chuẩn đoán hình ảnh",
      "Tóm tắt bệnh án/ hồ sơ bệnh án/ sổ khám bệnh",
    ],
  },
];

export const baoHiem = [
  {
    Title: "Giải quyết quyền lợi bảo hiểm Thương tật toàn bộ và vĩnh viễn:",
  },
  {
    content: [
      " Giấy ra viện/ Giấy chứng nhận phẫu thuật/ Tóm tắt bệnh án/ Hồ sơ bệnh án/ Hồ sơ khám và chuẩn đoán ",
      "Các xét nghiệm, chuẩn đoán hình ảnh",
      "Kết quả Giám định y khoa do Hội đồng Giám định Y khoa (cấp Trung ương/ Tỉnh hoặc Thành phố trực thuộc trung ương nơi người bị thương tật đang cư ngụ) thực hiện sau 6 tháng và không trễ hơn 9 tháng, kể từ ngày xảy ra tai nạn (không yêu cầu giám định y khoa trong trường hợp phẫu thuật đoạn chi hoặc phẫu thuật múc bỏ nhãn cầu do chấn thương)",

      " Kết luận điều tra, Biên bản tai nạn, Biên bản hiện trường do Cơ quan Công an có thẩm quyền cấp (nếu sự kiện bảo hiểm liên quan đến tai nạn)",
      "  Các giấy tờ chứng minh quan hệ giữa người được bảo hiểm và bên nhận quyền lợi bảo hiểm (CMND/CCCD, Giấy kết hôn, Giấy khai sinh, Văn bản ủy quyền...)",
    ],
  },
];
export const taiNan = [
  {
    Title: "Quyền lợi bảo hiểm Tai nạn cá nhân:",
  },
  {
    content: [
      " Giấy ra viện/ Giấy chứng nhận phẫu thuật/ Tóm tắt bệnh án/ Hồ sơ bệnh án/ Hồ sơ khám và chuẩn đoán;",
      "Các xét nghiệm, chuẩn đoán hình ảnh",
      "Kết luận điều tra, biên bản tai nạn, biên bản hiện trường do Cơ quan Công an có thẩm quyền cấp.",
    ],
  },
];
export const tuVong = [
  {
    Title: "Quyền lợi tử vong",
  },
  {
    content: [
      " Trích lục khai tử/ Giấy báo tử;",
      "Tường trình về tình huống và nguyên nhân tử vong của Người được bảo hiểm;",
      "Các bằng chứng chứng minh nguyên nhân tử vong: \n Tử vong do bệnh lý: Giấy ra viện, Tóm tắt bệnh án/ Trích sao Hồ sơ bệnh án, Tài liệu khác chứng minh nguyên nhân tử vong. \nTử vong do tai nạn: Kết luận điều tra, Biên bản tai nạn và kết quả giám định pháp y.",
      "Bằng chứng hợp pháp về quyền nhận quyền lợi bảo hiểm: Bản sao CMND/CCCD + Giấy ủy quyền/ Di chúc/ Văn bản thỏa thuận của người nhận quyền lợi bảo hiểm trong trường hợp thừa kế theo pháp luật có xác nhận của cơ quan có thẩm quyền.",
    ],
  },
];
