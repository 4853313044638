import React from 'react';
import styled from 'styled-components';

const Summary = props => {
    return (
        <BoxSummary>
            <TextSummary>{props.title}</TextSummary>

            <IconSummary>
                <img src={props.src} alt="" style={{}} />
            </IconSummary>
        </BoxSummary>
    );
};

export default Summary;
const BoxSummary = styled.div`
    height: 92px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
`;
const TextSummary = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #212b36;
    margin: 24px;
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        width: 256px;
        height: 44px;
    }
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        width: auto;
        height: auto;
        margin: 14px;
        font-size: 13px;
        line-height: 22px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        width: auto;
        height: auto;
        font-size: 14px;
        margin: 20px;
    }
`;
const IconSummary = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin: 28px 24px;
    @media screen and (min-width: 740px) and (max-width: 1023px) {
        width: 26px;
        height: 26px;
        margin: 14px 10px;
    }
`;
